<template>
  <div class="item text" :class="{collapsed: collapsed, deletewarn: showDeleteConfirm, loading: loading}">
    <ValidationObserver v-slot="{ dirty, invalid }" ref="observer">
    <div class="header" @click="collapsed = !collapsed">
      <b>Text</b> [ id: {{structure.id ? structure.id : '--'}} ] 
      <span class="toggle">&lt;</span>
      <span v-if="invalid" class="invalid">⚠</span>
      <span v-if="!editorStructure.id || dirty" class="unsaved">Unsaved Changes</span> 
      <span class="label">{{structure.txt1}}</span>
      <button class="insead white autowidth icon loader" v-show="!showSaveConfirm" :class="{dirty: dirty}" :disabled="loading || parentLoading" @click.stop="save">
        <img v-if="!loading" src="/save.png">
        <img v-else src="/spinner.svg">
      </button>
      <button class="insead white autowidth icon" v-show="showSaveConfirm" :disabled="loading || parentLoading" @click.stop="showSaveConfirm = false">Cancel</button>
      <button class="insead white autowidth icon" v-show="showSaveConfirm" :disabled="loading || parentLoading" @click.stop="save">Yes, save <i>invalid</i> item</button>
      <button class="insead white autowidth icon" v-show="!showDeleteConfirm" :disabled="loading || parentLoading" @click.stop="showDeleteConfirm = true"><img src="/delete.png"></button>
      <button class="insead white autowidth icon" v-show="showDeleteConfirm" :disabled="loading || parentLoading" @click.stop="showDeleteConfirm = false">Cancel</button>
      <button class="insead white autowidth icon" v-show="showDeleteConfirm" :disabled="loading || parentLoading" @click.stop="$emit('remove'); showDeleteConfirm = false;">Yes, confirm</button>
      <button class="insead white autowidth icon" @click.stop="$emit('up');" :disabled="loading || parentLoading" title="Move up"><img src="/triangle.png"></button>
      <button class="insead white autowidth icon" @click.stop="$emit('down');" :disabled="loading || parentLoading" title="Move down"><img style="transform: rotate(180deg);" src="/triangle.png"></button>
    </div>
    <table v-show="!collapsed">
      <!-- <tr><td>id:</td><td> <input type="number" v-model.number="structure.id"></td></tr> -->
      
      <tr class="inputrow">
        <td>caption:</td>
        <td> 
          <ValidationProvider :name="editorStructure.editorId + 'caption'" v-slot="{ classes, errors }">
            <input type="text" required v-model.trim="structure.caption" :disabled="loading || parentLoading || showSaveConfirm" :class="classes">
            <span class="errormessage">{{ errors[0] }}</span>
          </ValidationProvider>
        </td>
      </tr>
      <tr class="inputrow">
        <td>text:</td>
        <td> 
          <ValidationProvider :name="editorStructure.editorId + 'text'" v-slot="{ classes, errors }">
            <textarea ref="textarea" required type="text" v-model.trim="structure.text" :disabled="loading || parentLoading || showSaveConfirm" :class="classes"></textarea>
            <span class="errormessage">{{ errors[0] }}</span>
          </ValidationProvider>
        </td>
      </tr>      
      <tr class="inputrow">
        <td>action:</td>
        <td> 
          <ValidationProvider :name="editorStructure.editorId + 'action'" v-slot="{ classes, errors }">
            <input type="text" v-model.trim="structure.action" :disabled="loading || parentLoading || showSaveConfirm" :class="classes">
            <span class="errormessage">{{ errors[0] }}</span>
          </ValidationProvider>
        </td>
      </tr>
    </table>  
    </ValidationObserver>   
    <Snackbar ref="snackbar" /> 
  </div>
</template>


<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import '@/veevalidate.js'
import _ from 'lodash'
import axios from 'axios'
import Snackbar from '@/components/Snackbar.vue'

export default {
  name: 'TextItem',
  components:{
    ValidationProvider,
    ValidationObserver,
    Snackbar
  },
  props: {
    editorStructure: Object,
    parentLoading: Boolean
  },
  data: function() {
    return {
      showDeleteConfirm: false,
      showSaveConfirm: false,
      collapsed: false,
      loading: false,
      structure: {
        $type: 'TextData, Assembly-CSharp',
        type: "txt",
        id: undefined,
        caption: undefined,
        text: undefined,
        action: undefined
      }
    }
  },
  computed: {
  },
  methods: {
    generate(){
      let tmp = _.cloneDeep(this.structure)     
      return {json: tmp, fileList: []}
    },
    collapse(){
      this.collapsed = true
    },
    expand(){
      this.collapsed = false
    },
    autoSizeTextarea(){
      this.$refs.textarea.style.height = 'auto'
      this.$refs.textarea.style.height = (this.$refs.textarea.scrollHeight + 5)+"px";
    },
    async save(){
      await this.$refs.observer.validate()
      if(this.$refs.observer?.flags.invalid && !this.showSaveConfirm){
        this.showSaveConfirm = true
        return
      }

      try{
        this.loading = true
        // eslint-disable-next-line
        const {list, ...temp} = this.structure
        temp.id = this.editorStructure.id
        let resp = await axios({ url: `content/item${this.structure.id ? ('/'+this.structure.id) : ''}`, data: ({
          structure: JSON.stringify(temp), 
          parentId: this.editorStructure.parentId,
          order: this.editorStructure.order}), method: "POST" })
        this.editorStructure.id = this.structure.id = resp.data.id // update for newly created
        this.$refs.observer.reset()
        await this.$refs.observer.validate()
      }
      catch(e){
        this.$refs.snackbar.show('Something went wrong :(')
        console.log(e)
      }
      finally{
        this.loading = false
        this.showSaveConfirm = false
      }
    }
  },
  watch: {  
    'structure.text': function(){
      this.autoSizeTextarea()
    },
    'editorStructure.order' : async function(n, o) {
      console.log(`Order changed for item: ${this.structure.id} from ${o} to ${n}`)
      try{
        this.loading = true
        if(this.structure.id)
          await axios.post(`content/${this.structure.id}/reorder/${n}`)
      }      
      catch(e){
        this.$refs.snackbar.show('Something went wrong :(')
        console.log(e)
      }
      finally{
        this.loading = false
      }      
    }  
  },
  async mounted(){
    // TODO delete confirm red background missing / not working

    if(this.editorStructure?.structure)
      Object.assign(this.structure, this.editorStructure?.structure)
    
    this.structure.id = this.editorStructure.id

    // if(!this.editorStructure.id){  // newly created
    //   await this.save()
      // console.log(this.$refs.observer.refs) //(field => field.setFlags({ dirty: true }));
    // }
    
    // initial validation    
    setTimeout(() => this.$refs.observer.validate(), 300)

    setTimeout(() => this.autoSizeTextarea(), 500)
  }
}
</script>



<style lang="scss">

.contenteditor{
  .item.text {

  }
}

</style>
