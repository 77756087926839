<template>
  <div class="item question" :class="{collapsed: collapsed, deletewarn: showDeleteConfirm, loading: loading}">
    <ValidationObserver v-slot="{ dirty, invalid }" ref="observer">
    <div class="header" @click="collapsed = !collapsed">
      <b>Question</b> [ id: {{structure.id ? structure.id : '--'}} ] 
      <span class="toggle">&lt;</span> 
      <span v-if="invalid" class="invalid">⚠</span>
      <span v-if="!editorStructure.id || dirty" class="unsaved">Unsaved Changes</span> 
      <span class="label">{{structure.Q ? `${structure.Q.substring(0,60)}${structure.Q.length > 60 ? '...' : ''}` : ''}}</span>
      <button class="insead white autowidth icon loader" v-show="!showSaveConfirm" :class="{dirty: dirty}" :disabled="loading || parentLoading" @click.stop="save">
        <img v-if="!loading" src="/save.png">
        <img v-else src="/spinner.svg">
      </button>
      <button class="insead white autowidth icon" v-show="showSaveConfirm" :disabled="loading || parentLoading" @click.stop="showSaveConfirm = false">Cancel</button>
      <button class="insead white autowidth icon" v-show="showSaveConfirm" :disabled="loading || parentLoading" @click.stop="save">Yes, save <i>invalid</i> item</button>
      <button class="insead white autowidth icon" v-show="!showDeleteConfirm" :disabled="loading || parentLoading" @click.stop="showDeleteConfirm = true"><img src="/delete.png"></button>
      <button class="insead white autowidth icon" v-show="showDeleteConfirm" :disabled="loading || parentLoading" @click.stop="showDeleteConfirm = false">Cancel</button>
      <button class="insead white autowidth icon" v-show="showDeleteConfirm" :disabled="loading || parentLoading" @click.stop="$emit('remove'); showDeleteConfirm = false;">Yes, confirm delete</button>
      <button class="insead white autowidth icon" @click.stop="$emit('up');" :disabled="loading || parentLoading" title="Move up"><img src="/triangle.png"></button>
      <button class="insead white autowidth icon" @click.stop="$emit('down');" :disabled="loading || parentLoading" title="Move down"><img style="transform: rotate(180deg);" src="/triangle.png"></button>      
    </div>
    <table v-show="!collapsed">
      <!-- <tr><td>id:</td><td> <input type="number" v-model.number="structure.id"></td></tr> -->
      <tr class="inputrow">
        <td>type:</td>
        <td> 
          <ValidationProvider :name="editorStructure.editorId + 'type'" v-slot="{ classes, errors }">
            <input type="text" v-model.trim="structure.type" style="width: 100px;" :disabled="loading || parentLoading || showSaveConfirm" :class="classes"> TODO dropdown (one example is "rating", the rest I don't know)
            <span class="errormessage">{{ errors[0] }}</span>
          </ValidationProvider>
        </td>
      </tr>      
      <tr class="inputrow">
        <td>Q:</td>
        <td> 
          <ValidationProvider :name="editorStructure.editorId + 'Q'" v-slot="{ classes, errors }">
            <input type="text" required v-model.trim="structure.Q" :disabled="loading || parentLoading || showSaveConfirm" :class="classes">
            <span class="errormessage">{{ errors[0] }}</span>
          </ValidationProvider>
        </td>
      </tr>      
      <tr class="inputrow">
        <td>Qdashboard:</td>
        <td> 
          <ValidationProvider :name="editorStructure.editorId + 'Qdashboard'" v-slot="{ classes, errors }">
            <input type="text" v-model.trim="structure.Qdashboard" :disabled="loading || parentLoading || showSaveConfirm" :class="classes">
            <span class="errormessage">{{ errors[0] }}</span>
          </ValidationProvider>
        </td>
      </tr>  
      <tr class="inputrow">
        <td>showValueAverage:</td>
        <td> 
          <ValidationProvider :name="editorStructure.editorId + 'showValueAverage'" v-slot="{ classes, errors }">
            <input type="checkbox" v-model="structure.showValueAverage" :disabled="loading || parentLoading || showSaveConfirm" :class="classes">
            <span class="errormessage">{{ errors[0] }}</span>
          </ValidationProvider>
        </td>
      </tr>    
      <tr class="inputrow">
        <td>valueAverageLabel:</td>
        <td> 
          <ValidationProvider :name="editorStructure.editorId + 'valueAverageLabel'" v-slot="{ classes, errors }">
            <input type="text" v-model.trim="structure.valueAverageLabel" :disabled="loading || parentLoading || showSaveConfirm" :class="classes">
            <span class="errormessage">{{ errors[0] }}</span>
          </ValidationProvider>
        </td>
      </tr>  
      <tr class="inputrow">
        <td>Answers:</td>
        <td>
          <ValidationProvider :name="editorStructure.editorId + 'answers'" v-slot="{ classes, errors }">
            <input style="width: 50%;" type="text" v-model="Answers_internal" :disabled="loading || parentLoading || showSaveConfirm" :class="classes">
            <span class="errormessage">{{ errors[0] }}</span>
          </ValidationProvider>
          <span class="path" :class="{invalid: !p || !structure.items.map(i => i.optionId).includes(parseInt(p))}" v-for="(p,index) in structure.Answers" :key="p + index">{{p ? p : 'invalid'}}</span>
        </td>
      </tr>  
      <tr class="inputrow">
        <td>showAnswer:</td>
        <td> 
          <ValidationProvider :name="editorStructure.editorId + 'showAnswer'" v-slot="{ classes, errors }">
            <input type="checkbox" v-model="structure.showAnswer" :disabled="loading || parentLoading || showSaveConfirm" :class="classes"> TODO what is this actually? 
            <span class="errormessage">{{ errors[0] }}</span>
          </ValidationProvider>
        </td>
      </tr> 
      <tr class="inputrow">
        <td>ranking:</td>
        <td> 
          <ValidationProvider :name="editorStructure.editorId + 'ranking'" v-slot="{ classes, errors }">
            <input type="checkbox" v-model="structure.ranking" :disabled="loading || parentLoading || showSaveConfirm" :class="classes">
            <span class="errormessage">{{ errors[0] }}</span>
          </ValidationProvider>
        </td>
      </tr>  
      <tr class="inputrow">
        <td>path:</td>
        <td>
          <ValidationProvider :name="editorStructure.editorId + 'path'" v-slot="{ classes, errors }">
            <input style="width: 50%;" type="text" v-model="path_internal" :disabled="loading || parentLoading || showSaveConfirm" :class="classes">
            <span class="errormessage">{{ errors[0] }}</span>
          </ValidationProvider>
          <span class="path" :class="{invalid: !p}" v-for="(p,index) in structure.path" :key="p + index">{{(p && p.length) ? p : 'invalid'}}</span>
        </td>
      </tr>  
      <tr class="inputrow">
        <td>maxOptions:</td>
        <td> 
          <ValidationProvider :name="editorStructure.editorId + 'maxOptions'" v-slot="{ classes, errors }">
            <input type="number" v-model.number="structure.maxOptions" style="width: 100px;" :disabled="loading || parentLoading || showSaveConfirm" :class="classes"> TODO what's this?
            <span class="errormessage">{{ errors[0] }}</span>
          </ValidationProvider>
        </td>
      </tr>
      <tr>
        <td>items:</td>
        <td class="expandbuttons">
          <button class="insead white autowidth icon" @click="collapseAll" title="Collapse all items"><img src="/collapse.png"></button>
          <button class="insead white autowidth icon" @click="expandAll" title="Expand all items"><img src="/expand.png"></button>
        </td>
      </tr>
    </table>
    </ValidationObserver>  
    <div class="children" v-show="!collapsed">
      <div class="label" v-if="structure.items && structure.items.length">
        <div>Question: {{structure.id}} - <span>{{structure.Q ? `${structure.Q.substring(0,20)}...` : ''}}</span></div>
      </div>
      <div class="items">
        <div class="insertactions" v-if="structure.id">
          <img width="16" height="16" src="/plus.png" />
          <div>
            <button class="insead autowidth white" @click="addItem(0, 'option')" >Option</button>
          </div>
        </div> 
        <div style="color: #a0a0a0;" v-else>Note: newly added question must be saved before child option items can be added.</div>
        <template v-for="(item, index) in structure.items" >
          <OptionItem :key="item.editorId"
            ref="items"
            :editorStructure="item"
            :parentLoading="loading || parentLoading"
            @remove="removeItem(index)"
            @up="moveUp(index)"
            @down="moveDown(index)"
          /> 
          <div class="insertactions" v-show="structure.id" :key="generateEditorId() +'actions_' + index">
            <img width="16" height="16" src="/plus.png" />
            <div>
              <button class="insead autowidth white" @click="addItem(index+1, 'option')" >Option</button>
            </div>
          </div>          
        </template>
      </div>
    </div>
    <Snackbar ref="snackbar" /> 
  </div>
</template>


<script>
// eslint-disable-next-line
import {getEmptyContent, generateEditorId} from '@/utils.js'
import OptionItem from '@/components/content/Option.vue'
import axios from 'axios'
import _ from 'lodash'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import '@/veevalidate.js'
import Snackbar from '@/components/Snackbar.vue'

export default {
  name: 'QuestionItem',
  components: {
    OptionItem,
    ValidationProvider,
    ValidationObserver,
    Snackbar
  },
  props: {
    editorStructure: Object,
    parentLoading: Boolean
  },
  data: function() {
    return {
      showDeleteConfirm: false,
      showSaveConfirm: false,
      collapsed: false,
      loading: false,
      structure: {
        id: undefined,
        type: undefined,
        Q: undefined,
        Qdashboard: undefined,
        showValueAverage: false,
        valueAverageLabel: undefined,        
        Answers: undefined,
        showAnswer: undefined,
        ranking: undefined,
        path: undefined,
        maxOptions: undefined,
        items: []
      }
    }
  },
  computed: {
    path_internal: {
      get: function () {
        let tmp = this.structure.path?.join(',')
        return tmp?.length ? tmp : undefined
      },
      set: function (newValue) {
        let tmp = newValue?.trim().replace(/[^0-9,]+/g, '')
        
        this.structure.path = tmp.length ? tmp.split(',') : undefined
      }
    },
    Answers_internal: {
      get: function () {
        let tmp = this.structure.Answers?.join(',')
        return tmp?.length ? tmp : undefined
      },
      set: function (newValue) {
        let tmp = newValue?.trim().replace(/[^0-9,]+/g, '')
        
        this.structure.Answers = tmp.length ? tmp.split(',') : undefined
      }
    }
  },
  methods: {   
    generateEditorId(){
      return generateEditorId()
    },   
    addItem(index, type){ 
      let newItem = {
        editorId: generateEditorId(),
        parentId: Number(this.editorStructure.id),
        order: index,
        structure: getEmptyContent(type)
      }
      this.structure.items.splice(index, 0, newItem)       
      for (let i = 0; i < this.structure.items.length; i++) {
        this.structure.items[i].order = i          
      }  
    },
    generate(){
      let tmp = _.cloneDeep(this.structure)  
      const childNodes = this.$refs.items? this.$refs.items.map(i => i.generate()) : []
      tmp.items = childNodes.map(n => n.json)
      tmp.path = tmp.path?.filter(x => x).map(x => parseInt(x))
      tmp.Answers = tmp.Answers?.filter(x => x).map(x => parseInt(x))
      return {json: tmp, fileList: childNodes.flatMap(f => f.fileList)}
    },
    collapse(){
      this.collapsed = true
    },
    expand(){
      this.collapsed = false
    },
    collapseAll(){
      this.$refs.items?.forEach(child => {
        child.collapse()
      })
    },
    expandAll(){
      this.$refs.items?.forEach(child => {
        child.expand()
      })
    },
    async removeItem(index){
      if(this.structure.items[index].id)
        await axios({ url: `content/${this.structure.items[index].id}`, method: "DELETE" })
      this.structure.items.splice(index, 1)
    },
    moveDown(index){      
      if(index < (this.structure.items.length + 1)){
        this.structure.items.move(index, index + 1)
        for (let i = 0; i < this.structure.items.length; i++) {
          this.structure.items[i].order = i          
        }
      }
    },
    moveUp(index){      
      if(index > 0){
        this.structure.items.move(index, index - 1)
        for (let i = 0; i < this.structure.items.length; i++) {
          this.structure.items[i].order = i          
        }
      }
    },
    async save(){
      await this.$refs.observer.validate()
      if(this.$refs.observer?.flags.invalid && !this.showSaveConfirm){
        this.showSaveConfirm = true
        return
      }

      try{
        this.loading = true
        // eslint-disable-next-line
        const {items, ...temp} = this.structure
        temp.id = this.editorStructure.id
        let resp = await axios({ url: `content/item${this.structure.id ? ('/'+this.structure.id) : ''}`, data: ({
          structure: JSON.stringify(temp), 
          parentId: this.editorStructure.parentId,
          order: this.editorStructure.order}), method: "POST" })
        this.editorStructure.id = this.structure.id = resp.data.id // update for newly created
        this.$refs.observer.reset()
        await this.$refs.observer.validate()
      }
      catch(e){
        this.$refs.snackbar.show('Something went wrong :(')
        console.log(e)
      }
      finally{
        this.loading = false
        this.showSaveConfirm = false
      }
    }
  },
  watch: {  
    'editorStructure.order' : async function(n, o) {
      console.log(`Order changed for item: ${this.structure.id} from ${o} to ${n}`)
      try{
        this.loading = true
        if(this.structure.id)
          await axios.post(`content/${this.structure.id}/reorder/${n}`)
      }      
      catch(e){
        this.$refs.snackbar.show('Something went wrong :(')
        console.log(e)
      }
      finally{
        this.loading = false
      }  
    }  
  },
  async mounted(){
    // Support parsing of old style question options
    // if(!this.editorStructure.items){
    //   const regex = /A[1-9]+/
    //   this.editorStructure.items = []
    //   for (const property in this.editorStructure) {
    //     if(regex.test(property) && this.editorStructure[property]){
    //       let tmp = getEmptyContent('option')
    //       tmp.optionId = parseInt(property.substring(1))
    //       tmp.text = this.editorStructure[property]
    //       this.editorStructure.items.push(tmp)
    //       delete this.editorStructure[property]   // remove A1...An properties from structure
    //     }
    //   }      
    // }

    this.editorStructure?.children?.forEach(item => {
      item.editorId = item.editorId ?? generateEditorId()
    })  
    if(this.editorStructure?.structure)
      Object.assign(this.structure, this.editorStructure?.structure)
    if(this.editorStructure?.children)
      this.structure.items = _.sortBy(this.editorStructure.children.map(c => {c.structure = JSON.parse(c.structure); return c;}),'order')
    this.structure.id = this.editorStructure.id

    // if(!this.editorStructure.id)  // newly created
    //   await this.save()

    // initial validation
    setTimeout(() => this.$refs.observer.validate(), 300)
  }
}
</script>



<style lang="scss">

.contenteditor{
  .item.question {
    .insertactions{
      > div{
        width: 80px;
        left: 28px;
      }
    }
  }
}

</style>
