<template>
  <div class="item option" :class="{collapsed: collapsed, deletewarn: showDeleteConfirm, loading: loading}">
    <ValidationObserver v-slot="{ dirty, invalid }" ref="observer">
    <div class="header" :title="`Click to ${collapsed ? 'expand' : 'collapse'}`" @click="collapsed = !collapsed">
      <b>Option</b> [ optionId: {{structure.optionId ? structure.optionId : '--'}} ] 
      <span class="toggle">&lt;</span>
      <span v-if="invalid" class="invalid">⚠</span>
      <span v-if="!editorStructure.id || dirty" class="unsaved">Unsaved Changes</span> 
      <span class="label">{{structure.text}}</span>
      <button class="insead white autowidth icon loader" v-show="!showSaveConfirm" :class="{dirty: dirty}" :disabled="loading || parentLoading" @click.stop="save">
        <img v-if="!loading" src="/save.png">
        <img v-else src="/spinner.svg">
      </button>
      <button class="insead white autowidth icon" v-show="showSaveConfirm" :disabled="loading || parentLoading" @click.stop="showSaveConfirm = false">Cancel</button>
      <button class="insead white autowidth icon" v-show="showSaveConfirm" :disabled="loading || parentLoading" @click.stop="save">Yes, save <i>invalid</i> item</button>
      <button class="insead white autowidth icon" v-show="!showDeleteConfirm" :disabled="loading || parentLoading" @click.stop="showDeleteConfirm = true" title="Remove item"><img src="/delete.png"></button>
      <button class="insead white autowidth icon" v-show="showDeleteConfirm" :disabled="loading || parentLoading" @click.stop="showDeleteConfirm = false">Cancel</button>
      <button class="insead white autowidth icon" v-show="showDeleteConfirm" :disabled="loading || parentLoading" @click.stop="$emit('remove'); showDeleteConfirm = false;">Yes, confirm</button>
      <button class="insead white autowidth icon" @click.stop="$emit('up');" :disabled="loading || parentLoading" title="Move up"><img src="/triangle.png"></button>
      <button class="insead white autowidth icon" @click.stop="$emit('down');" :disabled="loading || parentLoading" title="Move down"><img style="transform: rotate(180deg);" src="/triangle.png"></button>
    </div>
    <table v-show="!collapsed">
      <!-- <tr class="inputrow">
        <td>optionId:</td>
        <td>
          <input type="number" v-model.number="structure.optionId">
        </td>
        <td v-if="structure.image" class="preview" rowspan="4"><img :src="`${blobRoot}${$store.state.editorWebContentRootPath}${structure.image}`"></td>
      </tr> -->
      <tr class="inputrow">
        <td>image:</td>
        <td> 
          <ValidationProvider :name="editorStructure.editorId + 'image'" rules="imageUrl" v-slot="{ classes, errors }">
            <input type="text" v-model.trim="structure.image" :disabled="loading || parentLoading || showSaveConfirm" :class="classes">
            <span class="errormessage">{{ errors[0] }}</span>
          </ValidationProvider>
        </td>
        <td v-if="structure.image" class="preview" rowspan="3"><img :src="`${blobRoot}${$store.state.editorWebContentRootPath}${structure.image}`"></td>
      </tr>
      <tr class="inputrow">
        <td>text:</td>
        <td> 
          <ValidationProvider :name="editorStructure.editorId + 'text'" v-slot="{ classes, errors }">
            <input type="text" required v-model.trim="structure.text" :disabled="loading || parentLoading || showSaveConfirm" :class="classes">
            <span class="errormessage">{{ errors[0] }}</span>
          </ValidationProvider>
        </td>
      </tr>
      <tr class="inputrow">
        <td>textDashboard:</td>
        <td> 
          <ValidationProvider :name="editorStructure.editorId + 'textDashboard'" v-slot="{ classes, errors }">
            <input type="text" v-model.trim="structure.textDashboard" :disabled="loading || parentLoading || showSaveConfirm" :class="classes">
            <span class="errormessage">{{ errors[0] }}</span>
          </ValidationProvider>
        </td>
      </tr>      
    </table>    
    </ValidationObserver>   
    <Snackbar ref="snackbar" /> 
  </div>
</template>


<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import '@/veevalidate.js'
import _ from 'lodash'
import axios from 'axios'
import Snackbar from '@/components/Snackbar.vue'
import {validateS3Files} from '@/utils.js'

export default {
  name: 'OptionItem',
  components:{
    ValidationProvider,
    ValidationObserver,
    Snackbar
  },
  props: {
    editorStructure: Object,
    parentLoading: Boolean
  },
  data: function() {
    return {
      blobRoot: process.env.VUE_APP_PUBLIC_BLOB,
      showDeleteConfirm: false,
      showSaveConfirm: false,
      collapsed: false,
      loading: false,  
      fileFields: ['image'],
      structure: {        
        optionId: undefined,
        image: undefined,
        text: '',
        textDashboard: undefined
      }
    }
  },
  computed: {    
  },
  methods: {
    generate(){
      let tmp = _.cloneDeep(this.structure) 
      return {json: tmp, fileList: Array.from(this.editorStructure.fileList ?? [])}
    },
    collapse(){
      this.collapsed = true
    },
    expand(){
      this.collapsed = false
    },
    async save(){
      await this.$refs.observer.validate()
      if((!await validateS3Files(this) || this.$refs.observer?.flags.invalid) && !this.showSaveConfirm){
        this.showSaveConfirm = true
        return
      }

      try{
        this.loading = true
        // eslint-disable-next-line
        const {list, ...temp} = this.structure
        temp.optionId = this.editorStructure.id
        let resp = await axios({ url: `content/item${this.structure.optionId ? ('/'+this.structure.optionId) : ''}`, data: ({
          structure: JSON.stringify(temp), 
          fileList: this.editorStructure.fileList,
          parentId: this.editorStructure.parentId,
          order: this.editorStructure.order}), method: "POST" })
        this.editorStructure.id = this.structure.optionId = resp.data.id // update for newly created
        this.$refs.observer.reset()
        await this.$refs.observer.validate()
      }
      catch(e){
        this.$refs.snackbar.show('Something went wrong :(')
        console.log(e)
      }
      finally{
        this.loading = false
        this.showSaveConfirm = false
      }
    }
  },
  watch: {   
    'editorStructure.order' : async function(n, o) {
      console.log(`Order changed for item: ${this.structure.optionId} from ${o} to ${n}`)
      try{
        this.loading = true
        if(this.structure.optionId)
          await axios.post(`content/${this.structure.optionId}/reorder/${n}`)
      }      
      catch(e){
        this.$refs.snackbar.show('Something went wrong :(')
        console.log(e)
      }
      finally{
        this.loading = false
      }      
    }  
  },
  async mounted(){
    if(this.editorStructure?.structure)
      Object.assign(this.structure, this.editorStructure?.structure)
    
    this.structure.optionId = this.editorStructure.id

    // if(!this.editorStructure.id)  // newly created
    //   await this.save()
    
    // initial validation
    setTimeout(() => this.$refs.observer.validate(), 300)
  }
}
</script>



<style lang="scss">

.contenteditor{
  .item.option {
    
  }
}

</style>
