<template>
  <div class="item hotspot" :class="{collapsed: collapsed, deletewarn: showDeleteConfirm, loading: loading}">
    <ValidationObserver v-slot="{ dirty, invalid }" ref="observer"> 
    <div class="header" @click="collapsed = !collapsed">
      <b>Hotspot</b> [ id: {{structure.id ? structure.id : '--'}} ] 
      <span class="toggle">&lt;</span> 
      <span v-if="invalid" class="invalid">⚠</span>
      <span v-if="!editorStructure.id || dirty" class="unsaved">Unsaved Changes</span> 
      <span class="label">{{structure.text}}</span>
      <button class="insead white autowidth icon loader" v-show="!showSaveConfirm" :class="{dirty: dirty}" :disabled="loading || parentLoading" @click.stop="save">
        <img v-if="!loading" src="/save.png">
        <img v-else src="/spinner.svg">
      </button>
      <button class="insead white autowidth icon" v-show="showSaveConfirm" :disabled="loading || parentLoading" @click.stop="showSaveConfirm = false">Cancel</button>
      <button class="insead white autowidth icon" v-show="showSaveConfirm" :disabled="loading || parentLoading" @click.stop="save">Yes, save <i>invalid</i> item</button>
      <button class="insead white autowidth icon" v-show="!showDeleteConfirm" :disabled="loading || parentLoading" @click.stop="showDeleteConfirm = true"><img src="/delete.png"></button>
      <button class="insead white autowidth icon" v-show="showDeleteConfirm" :disabled="loading || parentLoading" @click.stop="showDeleteConfirm = false">Cancel</button>
      <button class="insead white autowidth icon" v-show="showDeleteConfirm" :disabled="loading || parentLoading" @click.stop="$emit('remove'); showDeleteConfirm = false;">Yes, confirm delete</button>
      <button class="insead white autowidth icon" @click.stop="$emit('up');" :disabled="loading || parentLoading" title="Move up"><img src="/triangle.png"></button>
      <button class="insead white autowidth icon" @click.stop="$emit('down');" :disabled="loading || parentLoading" title="Move down"><img style="transform: rotate(180deg);" src="/triangle.png"></button>      
    </div>
    <table v-show="!collapsed">
      <!-- <tr><td>id:</td><td colspan="2"> <input type="number" v-model.number="structure.id"></td></tr> -->
      <tr class="inputrow">
        <td>textureSize:</td>
        <td colspan="2"> 
          <ValidationProvider :name="editorStructure.editorId + 'textureSize'" v-slot="{ classes, errors }">
            <input type="number" required v-model.number="structure.textureSize" :disabled="loading || parentLoading || showSaveConfirm" :class="classes">
            <span class="errormessage">{{ errors[0] }}</span>
          </ValidationProvider>
        </td>
      </tr>   
      <tr class="inputrow">
        <td>text:</td>
        <td colspan="2"> 
          <ValidationProvider :name="editorStructure.editorId + 'text'" v-slot="{ classes, errors }">
            <input type="text" v-model.trim="structure.text" :disabled="loading || parentLoading || showSaveConfirm" :class="classes">
            <span class="errormessage">{{ errors[0] }}</span>
          </ValidationProvider>
        </td>
      </tr>       
      <tr class="inputrow">
        <td>hintImage:</td>
        <td> 
          <ValidationProvider :name="editorStructure.editorId + 'hintImage'" rules="imageUrl" v-slot="{ classes, errors }">
            <input type="text" v-model.trim="structure.hintImage" :disabled="loading || parentLoading || showSaveConfirm" :class="classes">
            <span class="errormessage">{{ errors[0] }}</span>
          </ValidationProvider>
        </td>
        <td v-if="structure.hintImage" class="preview" rowspan="3">
          <img :src="`${blobRoot}${$store.state.editorWebContentRootPath}${structure.hintImage}`">
        </td>
      </tr>
      <tr class="inputrow">
        <td>root:</td>
        <td> 
          <ValidationProvider :name="editorStructure.editorId + 'root'" v-slot="{ classes, errors }">
            <input type="checkbox" v-model="structure.root" :disabled="loading || parentLoading || showSaveConfirm" :class="classes">
            <span class="errormessage">{{ errors[0] }}</span>
          </ValidationProvider>
        </td>
      </tr> 
      <tr class="inputrow">
        <td>parentId:</td>
        <td> 
          <ValidationProvider :name="editorStructure.editorId + 'parentId'" v-slot="{ classes, errors }">
            <input type="number" v-model.number="structure.parentId" :disabled="structure.root || loading || parentLoading || showSaveConfirm" :class="classes">
            <span class="errormessage">{{ errors[0] }}</span>
          </ValidationProvider>
        </td>
      </tr>  
      <tr class="inputrow">
        <td>homeId:</td>
        <td> 
          <ValidationProvider :name="editorStructure.editorId + 'homeId'" v-slot="{ classes, errors }">
            <input type="number" v-model.number="structure.homeId" :disabled="loading || parentLoading || showSaveConfirm" :class="classes">
            <span class="errormessage">{{ errors[0] }}</span>
          </ValidationProvider>
        </td>
      </tr> 
      <tr class="inputrow">
        <td>mapImage:</td>
        <td> 
          <ValidationProvider :name="editorStructure.editorId + 'mapImage'" rules="imageUrl" v-slot="{ classes, errors }">
            <input type="text" v-model.trim="structure.mapImage" :disabled="loading || parentLoading || showSaveConfirm" :class="classes">
            <span class="errormessage">{{ errors[0] }}</span>
          </ValidationProvider>
        </td>
        <td v-if="structure.mapImage" class="preview" rowspan="3">
          <img :src="`${blobRoot}${$store.state.editorWebContentRootPath}${structure.mapImage}`">
        </td>
      </tr>      
      <tr class="inputrow">
        <td>showHome:</td>
        <td> 
          <ValidationProvider :name="editorStructure.editorId + 'showHome'" v-slot="{ classes, errors }">
            <input type="checkbox" v-model="structure.showHome" :disabled="loading || parentLoading || showSaveConfirm" :class="classes">
            <span class="errormessage">{{ errors[0] }}</span>
          </ValidationProvider>
        </td>
      </tr>  
      <tr class="inputrow">
        <td>showTimer:</td>
        <td> 
          <ValidationProvider :name="editorStructure.editorId + 'showTimer'" v-slot="{ classes, errors }">
            <input type="checkbox" v-model="structure.showTimer" :disabled="loading || parentLoading || showSaveConfirm" :class="classes">
            <span class="errormessage">{{ errors[0] }}</span>
          </ValidationProvider>
        </td>
      </tr> 
      <tr class="inputrow">
        <td>maxClicks:</td>
        <td> 
          <ValidationProvider :name="editorStructure.editorId + 'maxClicks'" v-slot="{ classes, errors }">
            <input type="number" v-model.number="structure.maxClicks" :disabled="loading || parentLoading || showSaveConfirm" :class="classes">
            <span class="errormessage">{{ errors[0] }}</span>
          </ValidationProvider>
        </td>
      </tr>       
      <tr class="inputrow">
        <td>path:</td>
        <td colspan="2">
          <ValidationProvider :name="editorStructure.editorId + 'path'" v-slot="{ classes, errors }">
            <input style="width: 50%;" type="text" v-model="path_internal" :disabled="loading || parentLoading || showSaveConfirm" :class="classes">
            <span class="errormessage">{{ errors[0] }}</span>
          </ValidationProvider>
          <span class="path" :class="{invalid: !p}" v-for="(p,index) in structure.path" :key="p + index">{{(p && (p.length || (!isNaN(p)))) ? p : 'invalid'}}</span>
        </td>
      </tr>        
      <tr>
        <td>items:</td>
        <td class="expandbuttons" colspan="2">
          <button class="insead white autowidth icon" @click="collapseAll" title="Collapse all items"><img src="/collapse.png"></button>
          <button class="insead white autowidth icon" @click="expandAll" title="Expand all items"><img src="/expand.png"></button>
        </td>
      </tr>
    </table>
    </ValidationObserver>  
    <div class="children" v-show="!collapsed">
      <div class="label" v-if="structure.items && structure.items.length">
        <span v-if="$refs.observer.flags.dirty || $refs.observer.flags.invalid" :class="{dirty: $refs.observer.flags.dirty, invalid: $refs.observer.flags.invalid}">⚠</span>
        <div>Hotspot: {{structure.id}} - <span>{{structure.text}}</span></div>
      </div>
      <div class="items">
        <div class="insertactions" v-if="structure.id">
          <img width="16" height="16" src="/plus.png" />
          <div style="width: 120px;">
            <button class="insead autowidth white" @click="addItem(0, 'hotspotitem')" >Hotspot Item</button>
          </div>
        </div> 
        <div style="color: #a0a0a0;" v-else>Note: newly added hotspot must be saved before child hotspot items can be added.</div>
        <template v-for="(item, index) in structure.items" >
          <HotspotItem :key="item.editorId"
            ref="items"
            :editorStructure="item"
            :parentLoading="loading || parentLoading"
            @remove="removeItem(index)"
            @up="moveUp(index)"
            @down="moveDown(index)"
          /> 
          <div class="insertactions" v-show="structure.id" :key="generateEditorId() +'actions_' + index">
            <img width="16" height="16" src="/plus.png" />
            <div style="width: 120px;">
              <button class="insead autowidth white" @click="addItem(index+1, 'hotspotitem')" >Hotspot Item</button>
            </div>
          </div>          
        </template>
      </div>
    </div>
    <Snackbar ref="snackbar" /> 
  </div>
</template>


<script>
// eslint-disable-next-line
import {getEmptyContent, generateEditorId, validateS3Files} from '@/utils.js'
import HotspotItem from '@/components/content/HotspotItem.vue'
import axios from 'axios'
import _ from 'lodash'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import '@/veevalidate.js'
import Snackbar from '@/components/Snackbar.vue'

export default {
  name: 'Hotspot',
  components: {
    HotspotItem,
    ValidationProvider,
    ValidationObserver,
    Snackbar
  },
  props: {
    editorStructure: Object,
    parentLoading: Boolean
  },
  data: function() {
    return {
      blobRoot: process.env.VUE_APP_PUBLIC_BLOB,
      showDeleteConfirm: false,
      showSaveConfirm: false,
      collapsed: false,
      loading: false,
      fileFields: ['hintImage', 'mapImage'],
      structure: {
        type: 'hotspots',
        id: undefined,
        textureSize: undefined,        
        hintImage: undefined,
        mapImage: undefined,
        root: undefined,
        parentId: undefined,
        homeId: undefined,        
        showHome: undefined,
        showTimer: undefined,
        maxClicks: undefined,
        text: undefined,
        path: undefined,
        items: []
      }
    }
  },
  computed: {
    path_internal: {
      get: function () {
        let tmp = this.structure.path?.join(',')
        return tmp?.length ? tmp : undefined
      },
      set: function (newValue) {
        let tmp = newValue?.trim().replace(/[^0-9,]+/g, '')
        
        this.structure.path = tmp.length ? tmp.split(',') : undefined
      }
    }
  },
  methods: {   
    generateEditorId(){
      return generateEditorId()
    },   
    addItem(index, type){ 
      let newItem = {
        editorId: generateEditorId(),
        parentId: Number(this.editorStructure.id),
        order: index,
        structure: getEmptyContent(type)
      }
      this.structure.items.splice(index, 0, newItem)       
      for (let i = 0; i < this.structure.items.length; i++) {
        this.structure.items[i].order = i          
      }  
    },
    generate(){
      let tmp = _.cloneDeep(this.structure)  
      const childNodes = this.$refs.items? this.$refs.items.map(i => i.generate()) : []
      tmp.items = childNodes.map(n => n.json)
      tmp.path = tmp.path?.filter(x => x).map(x => parseInt(x))
      if (tmp.root)
        delete tmp.parentId
      return {json: tmp, fileList: (this.editorStructure.fileList ?? []).concat(childNodes.flatMap(f => f.fileList))}
    },
    collapse(){
      this.collapsed = true
    },
    expand(){
      this.collapsed = false
    },
    collapseAll(){
      this.$refs.items?.forEach(child => {
        child.collapse()
      })
    },
    expandAll(){
      this.$refs.items?.forEach(child => {
        child.expand()
      })
    },
    async removeItem(index){
      if(this.structure.items[index].id)
        await axios({ url: `content/${this.structure.items[index].id}`, method: "DELETE" })
      this.structure.items.splice(index, 1)
    },
    moveDown(index){      
      if(index < (this.structure.items.length + 1)){
        this.structure.items.move(index, index + 1)
        for (let i = 0; i < this.structure.items.length; i++) {
          this.structure.items[i].order = i          
        }
      }
    },
    moveUp(index){      
      if(index > 0){
        this.structure.items.move(index, index - 1)
        for (let i = 0; i < this.structure.items.length; i++) {
          this.structure.items[i].order = i          
        }
      }
    },
    async save(){
      await this.$refs.observer.validate()
      if((!await validateS3Files(this) || this.$refs.observer?.flags.invalid) && !this.showSaveConfirm){
        this.showSaveConfirm = true
        return
      }

      try{
        this.loading = true
        // eslint-disable-next-line
        const {items, ...temp} = this.structure
        temp.id = this.editorStructure.id
        let resp = await axios({ url: `content/item${this.structure.id ? ('/'+this.structure.id) : ''}`, data: ({
          structure: JSON.stringify(temp), 
          fileList: this.editorStructure.fileList,
          parentId: this.editorStructure.parentId,
          order: this.editorStructure.order}), method: "POST" })
        this.editorStructure.id = this.structure.id = resp.data.id // update for newly created
        this.$refs.observer.reset()
        await this.$refs.observer.validate()
      }
      catch(e){
        this.$refs.snackbar.show('Something went wrong :(')
        console.log(e)
      }
      finally{
        this.loading = false
        this.showSaveConfirm = false
      }
    }
  },
  watch: {    
    'editorStructure.order' : async function(n, o) {
      console.log(`Order changed for item: ${this.structure.id} from ${o} to ${n}`)
      try{
        this.loading = true
        if(this.structure.id)
          await axios.post(`content/${this.structure.id}/reorder/${n}`)
      }      
      catch(e){
        this.$refs.snackbar.show('Something went wrong :(')
        console.log(e)
      }
      finally{
        this.loading = false
      }  
    }
  },
  async mounted(){
    this.editorStructure?.children?.forEach(item => {
      item.editorId = item.editorId ?? generateEditorId()
    })  
    if(this.editorStructure?.structure)
      Object.assign(this.structure, this.editorStructure?.structure)
    if(this.editorStructure?.children)
      this.structure.items = _.sortBy(this.editorStructure.children.map(c => {c.structure = JSON.parse(c.structure); return c;}),'order')
    this.structure.id = this.editorStructure.id

    // if(!this.editorStructure.id)  // newly created
    //   await this.save()

    // initial validation
    setTimeout(() => this.$refs.observer.validate(), 300)
  }
}
</script>



<style lang="scss">

.contenteditor{
  .item.hotspot {
    
  }
}

</style>
