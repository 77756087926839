<template>
<main class="mainlayout">
 
  <Sidebar/> 

  <div class="contenteditor">   
    <div class="textareas">
      <!-- <div class="source">
        <textarea v-model="jsonStructure"></textarea> 
        <button class="insead autowidth white" @click="parse">Parse</button>
      </div> -->
      <div class="target">
        <textarea v-model="jsonResult"></textarea> 
        <button class="insead autowidth white" @click="generate(false)" :disabled="loading">Get JSON Only</button>
        <div class="separator"></div>
        <button class="insead autowidth white" @click="draftModal = true" :disabled="loading">Generate Draft Version</button>
        <div class="separator"></div>
        <button class="insead autowidth" style="width: 100px;" @click="publishModal = true" :disabled="loading">
          <span v-if="!loading">PUBLISH</span>
          <img v-else src="/spinner.svg" style="height: 38px;">
        </button>
      </div>
    </div>
    
    <div class="publishinfo">
     {{message}}
    </div>
    
    <RootItem ref="root" :editorStructure="editorStructure" :parentLoading="loading" />
  </div>

  <Modal v-if="draftModal" @close="draftModal = false;">      
    <h3 slot="header">Generate draft version for experience</h3>
    <div slot="body">
      Generating a draft is just for internal testing. It will build the content tree, but will not publish it.
      <br>
      <br>
      Currently there is no real use case for this, but might be useful for admins / internal test teams to be able to preview it from the dashboard, etc...      
    </div>
    <div slot="footer">                
      <button class="insead white" @click="draftModal = false">Cancel</button> 
      <button class="insead green" @click="draftModal = false; generate(true)">Yes, generate draft version</button>               
    </div>
  </Modal> 

  <Modal v-if="publishModal" @close="publishModal = false;">      
    <h3 slot="header">Publish changes for experience</h3>
    <div slot="body">
      After publishing, any newly created sessions that reference content from this experience, will be using this latest version. 
      <br>
      <br>
      It will not affect sessions created earlier: for those only an indicator will be shown that some of the referenced content has been updated, and those sessions can be refreshed on-demand if needed.
    </div>
    <div slot="footer">                
      <button class="insead" @click="publishModal = false">Cancel</button> 
      <button class="insead red" @click="publishModal = false; publish()">Yes, publish new version</button>               
    </div>
  </Modal> 

  <Snackbar ref="snackbar" /> 
</main>
</template>

<script>
// @ is an alias to /src

import RootItem from '@/components/content/Root.vue'
import Sidebar from '@/components/Sidebar.vue'
import axios from 'axios'
import Snackbar from '@/components/Snackbar.vue'
import Modal from '@/components/Modal.vue'

export default {
  name: 'ContentBuilder',
  components: {
    RootItem,
    Sidebar,
    Snackbar,
    Modal
  },
  data: function(){
    return{
      loading: false,
      publishModal: false,
      draftModal: false,
      message: '',
      jsonStructure: '',
      editorStructure: undefined,
      jsonResult: ''
    }
  },
  computed:{
    // jsonResult(){
    //   return JSON.stringify(this.jsonTest)
    // }
  },
  methods:{
    parse(){
      this.editorStructure.structure = JSON.parse(this.editorStructure.structure)
    },
    async generate(saveDraft){      
      const contentTree = await this.$refs.root.generate(saveDraft)
      console.log(contentTree)
      this.jsonResult = JSON.stringify(contentTree.json)      
    },
    async publish(){
      try{
        this.loading = true
        this.$nprogress.start()
        this.message = 'Generating items and saving as draft for backup...'
        await this.generate(true)
        this.message = 'Publishing items...'
        await axios.post(`content/${this.editorStructure.id}/publish`)
        this.message = 'Publishing successful!'
      }
      catch(e){
        this.$refs.snackbar.show('Something went wrong during publishing :(')
        console.log(e)
        this.message = "Something went wrong during publishing :( " + e
      }
      finally{        
        this.$nprogress.done()
        this.loading = false
      }      
    },
    async loadContent(){
      //let resp = await axios({ url: `content/master/${this.$route.params.id}` })
      let resp = await axios({ url: `content/${this.$route.params.id}/tree` })
      this.editorStructure = resp.data
      this.message = "Last published on: " + (this.editorStructure.published ?? '--')
      //this.parse()
    }
  },
  created(){
    this.$watch(
      () => this.$route.params,
      (toParams, previousParams) => {
        // react to route changes...
        console.log(previousParams)
        console.log(toParams)
      }
    ),
    this.loadContent()
  }
}
</script>

<style lang="scss">

.modal-container{
    width: 600px;
  }
  .modal-footer{
    padding-top: 35px;

    > div{
      display: flex;
      justify-content: space-between;
    }

    button.insead{
      width: 270px;    
    }
  }

.contenteditor{

  width: 100%;

   input.dirty, textarea.dirty{
     border-left: 3px solid $green2;
     &.invalid{
       border-left: 3px solid $red;
     }
   }

   input.dirty[type=checkbox]{
     &:before{
       content: '.';
       color: transparent;
       border-left: 3px solid $green2;
       display: inline-block;
       position: relative;
       left: -4px;
     }    
     &.invalid:before{
       border-left: 3px solid $red;
     } 
   }

  .textareas{
    display:  flex;
    width: 100%;

    > div{
      width: 100%;
      padding: 10px;
      display: flex;

      &.source{
        border-right: 1px dashed #bbb;
      }
    }

    .separator{
      margin: 0 10px;
      border-right: 1px dashed #bbb;
    }

    textarea{
      width: 100%;
      height: 100%;
      min-height: 62px;
      margin-right: 10px;
    }
  }

  .publishinfo{
    padding-left: 10px;
  }
  

  .item{
    border: 1px solid rgba(0, 128, 128, 0.5);
    padding: 10px 5px 5px 10px;
    margin: 8px 0 8px 10px;
    border-radius: $borderradius;
    //overflow: hidden;

    &.deletewarn{
      border: 1px solid red;
      background-color: rgb(255, 228, 228);      
    }

    &.item.picture, &.item.option, &.item.hotspotitem, &.item.hotspot, &.item.segment {
      .preview{
        vertical-align: baseline;
        width: 30px;
        img{
          height: 88px;
          vertical-align: top;
          border-radius: $borderradius;
          &:hover{
            transform-origin: right;
            transform: scale(6);
            box-shadow: 0 0 10px rgb(206, 206, 206);
            border-radius: 0;
          }
        }
      }
    }

    span.path{
      background-color: rgb(220, 250, 196);
      padding: 0 3px;
      margin-left: 5px;
      border-radius: $borderradius;
      &.invalid{
        background-color: brown;
        color: white;
      }
    }

    .insertactions{
      position: relative;        
      //display: inline-block;
      margin-left: 10px;
      color: grey;
      height: 16px;      
      width: 136px;

      &:before, &:after{
        border-top: 1px dotted grey;
        content: ' ';
        width: 50px;
        display: inline-block;
        height: 8px;    
      }

      img{
        margin: 0 10px;
        opacity: 0.4;
      }

      > div{
        display: none;
        position: absolute;
        top: -55px;
        height: 55px;
        border-radius: 5px;
        box-shadow: 0 0 10px rgb(206, 206, 206);
        background-color: white;
        width: 180px;
        padding: 10px;        
      }

      &:hover{
        cursor: pointer;
        color: black;
        img{
          opacity: 1;
        }
        
        > div{          
          display: block;
        }
      }
    }

    
    button.icon{
      padding: 5px; 
      margin:5px 5px 5px 0; 
      height: 30px;

      img{
        opacity: 0.5;
        width: 18px;
        height: 18px;
      }

      &:hover{
        img{
          opacity: 1;
        }
      }
    }

    &:hover{
      > span .header button{
        visibility: visible;
      }
    }
    

    .header{
      margin-bottom: 5px;
      cursor: pointer;

      span.unsaved{
        margin-left: 15px;
        padding: 0 5px;
        border-radius: $borderradius;
        font-size: 14px;
        color: white;
        background-color: orange;
      }

      span.invalid{
        margin-left: 10px;
        color: $red;
        font-size: 14px;
      }

      button{
        visibility: hidden;
        margin: -5px 0 5px 5px;
        float: right;

        &.dirty{
          visibility: visible;
          background-color: rgb(227, 255, 232);
          border: 1px dotted $green1;
        }
      }

      .label{
        opacity: 0.3;
        padding-left: 20px;
      }

      .toggle{
        margin-left: 10px;
        display: inline-block;
        transform: rotate(90deg) scaleY(1.5);   
        opacity: 0.5;     
      }

      &:hover .toggle{
        transform: rotate(90deg) scaleY(1.5);  
        opacity: 1;
      }
    }

    &.collapsed .header{ 
      .label{
        opacity: 0.5;
      }

      .toggle {
        transform: rotate(-90deg) scaleY(1.5);
      }

      &:hover .toggle{
        transform: rotate(-90deg) scaleY(1.5);  
      }
    }

    .children{
      display: flex;   
      margin-top: 5px;

      .label{
        position: sticky;
        top: 15px;
        align-self: flex-start;
        font-size: 14px;
        margin-top: 10px;

        span{
          color: grey;
        }
        
        span.dirty{
          color: orange;
          padding-left: 2px;           
        }
        span.invalid{
          color: $red;
          padding-left: 2px; 
          font-weight: bold;         
        }

        div{
          //position: absolute;
          //left: -8px;
          writing-mode: vertical-rl;
          white-space: nowrap;
          // transform-origin: left;
          // transform: rotate(90deg); // translateY(1px) translateX(16px);
        }
      }

      .items{
        flex-grow: 1;
        .item{
          
        }
      }
    }
        
    input, textarea{
      padding: 4px;
      height: auto;

      &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: lightgray;
      }
    }

    table{ 
      width: 100%;  
      border-collapse: collapse;
      tr {        
        td:first-child{
          width: 50px;
          padding-right: 5px;
          padding-left: 0;
        }
      }  
    }

    &.loading{
      .header > button.loader{
        visibility: visible;
      }
    }
  }
}

</style>
