<template>
  <div class="item conditional" :class="{collapsed: collapsed, deletewarn: showDeleteConfirm, loading: loading}">
    <ValidationObserver v-slot="{ dirty, invalid }" ref="observer"> 
    <div class="header" @click="collapsed = !collapsed">
      <b>Conditional</b> [ id: {{structure.id ? structure.id : '--'}} ] 
      <span class="toggle">&lt;</span> 
      <span v-if="invalid" class="invalid">⚠</span>
      <span v-if="!editorStructure.id || dirty" class="unsaved">Unsaved Changes</span> 
      <span class="label">{{structure.txt1}}</span>
      <button class="insead white autowidth icon loader" v-show="!showSaveConfirm" :class="{dirty: dirty}" :disabled="loading || parentLoading" @click.stop="save">
        <img v-if="!loading" src="/save.png">
        <img v-else src="/spinner.svg">
      </button>
      <button class="insead white autowidth icon" v-show="showSaveConfirm" :disabled="loading || parentLoading" @click.stop="showSaveConfirm = false">Cancel</button>
      <button class="insead white autowidth icon" v-show="showSaveConfirm" :disabled="loading || parentLoading" @click.stop="save">Yes, save <i>invalid</i> item</button>
      <button class="insead white autowidth icon" v-show="!showDeleteConfirm" :disabled="loading || parentLoading" @click.stop="showDeleteConfirm = true"><img src="/delete.png"></button>
      <button class="insead white autowidth icon" v-show="showDeleteConfirm" :disabled="loading || parentLoading" @click.stop="showDeleteConfirm = false">Cancel</button>
      <button class="insead white autowidth icon" v-show="showDeleteConfirm" :disabled="loading || parentLoading" @click.stop="$emit('remove'); showDeleteConfirm = false;">Yes, confirm</button>
      <button class="insead white autowidth icon" @click.stop="$emit('up');" :disabled="loading || parentLoading" title="Move up"><img src="/triangle.png"></button>
      <button class="insead white autowidth icon" @click.stop="$emit('down');" :disabled="loading || parentLoading" title="Move down"><img style="transform: rotate(180deg);" src="/triangle.png"></button>
    </div>
    <table v-show="!collapsed">
      <!-- <tr><td>id:</td><td> <input type="number" v-model.number="structure.id"></td></tr> -->
      <tr class="inputrow"><td>txt1:</td><td> 
        <ValidationProvider name="txt1" v-slot="{ classes, errors }">
          <input type="text" required v-model.trim="structure.txt1" :disabled="loading || parentLoading || showSaveConfirm" :class="classes">
          <span class="errormessage">{{ errors[0] }}</span>
        </ValidationProvider>
      </td></tr>
      <tr class="inputrow"><td>txt2:</td><td>
        <ValidationProvider name="txt2" v-slot="{ classes, errors }">
          <input type="text" v-model.trim="structure.txt2" :disabled="loading || parentLoading || showSaveConfirm" :class="classes">
          <span class="errormessage">{{ errors[0] }}</span>
        </ValidationProvider>
      </td></tr>
      <tr class="inputrow"><td>icon:</td><td> 
        <ValidationProvider name="icon" rules="imageUrl" v-slot="{ classes, errors }">
          <input type="text" v-model.trim="structure.icon" :disabled="loading || parentLoading || showSaveConfirm" :class="classes">
          <span class="errormessage">{{ errors[0] }}</span>
        </ValidationProvider>
      </td></tr>
      <tr>
        <td>list:</td>
        <td class="expandbuttons">
          <button class="insead white autowidth icon" @click="collapseAll" title="Collapse all items"><img src="/collapse.png"></button>
          <button class="insead white autowidth icon" @click="expandAll" title="Expand all items"><img src="/expand.png"></button>
        </td>
      </tr>
    </table>
    </ValidationObserver> 
    <div class="children" v-show="!collapsed">
      <div class="label" v-if="structure.list && structure.list.length">
        <span v-if="$refs.observer.flags.dirty || $refs.observer.flags.invalid" :class="{dirty: $refs.observer.flags.dirty, invalid: $refs.observer.flags.invalid}">⚠</span>
        <div>Conditional: {{structure.id}} - <span>{{structure.txt1}}</span></div>
      </div>
      <div class="items">
        <div class="insertactions" v-if="structure.id">
          <img width="16" height="16" src="/plus.png" />
          <div style="width: 95px; left: 20px;">
            <button class="insead autowidth white" @click="addItem(0, 'seg')" >Segment</button>
          </div>
        </div> 
        <div style="color: #a0a0a0;" v-else>Note: newly added conditional must be saved before child items can be added.</div>
        <template v-for="(item, index) in structure.list" >
          <component v-if="itemType(item.structure)" :key="item.editorId"
            ref="list"
            :is="itemType(item.structure)"
            :editorStructure="item"
            :isConditional="true"
            :parentLoading="loading || parentLoading"
            @remove="removeItem(index)"
            @up="moveUp(index)"
            @down="moveDown(index)"
          /> 
          <div class="insertactions" v-if="itemType(item.structure)" :key="generateEditorId() +'actions_' + index">
            <img width="16" height="16" src="/plus.png" />
            <div style="width: 95px; left: 20px;">
              <button class="insead autowidth white" @click="addItem(index+1, 'seg')" >Segment</button>
            </div>
          </div>  
        </template>
      </div>
    </div>

    <Snackbar ref="snackbar" /> 
  </div>
</template>


<script>
// eslint-disable-next-line
import {getContentComponentType, getEmptyContent, generateEditorId, validateS3Files} from '@/utils.js'
import SegmentItem from '@/components/content/Segment.vue'
import axios from 'axios'
import _ from 'lodash'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import '@/veevalidate.js'
import Snackbar from '@/components/Snackbar.vue'

export default {
  name: 'ConditionalItem',
  components: {
    SegmentItem,
    ValidationProvider,
    ValidationObserver,
    Snackbar
  },
  props: {
    editorStructure: Object,
    parentLoading: Boolean
  },
  data: function() {
    return {
      showDeleteConfirm: false,
      showSaveConfirm: false,
      collapsed: false,
      loading: false,
      fileFields: ['icon'],
      structure: {
        type: "conditional",
        id: undefined,
        txt1: undefined,
        txt2: undefined,
        icon: undefined,
        list: []
      }
    }
  },
  computed: {
    // dirty(){
    //   return this.$refs.observer?.flags.dirty
    // },
    // invalid(){
    //   return this.$refs.observer?.flags.invalid
    // }
  },
  methods: {    
    generateEditorId(){
      return generateEditorId()
    },  
    addItem(index, type){ 
      let newItem = {
        editorId: generateEditorId(),
        parentId: Number(this.editorStructure.id),
        order: index,
        structure: getEmptyContent(type)
      }
      this.structure.list.splice(index, 0, newItem)
      for (let i = 0; i < this.structure.list.length; i++) {
        this.structure.list[i].order = i          
      } 
    }, 
    itemType(item){
      return getContentComponentType(item)
    },
    async generate(saveDraft){
      // eslint-disable-next-line
      let {list, ...tmp} = this.structure        
      const childNodes = this.$refs.list ? await Promise.all( this.$refs.list.map(async l => await l.generate(saveDraft))) : []  
      tmp.list = childNodes.map(n => n.json)
      const ret = {json: tmp, fileList: (this.editorStructure.fileList ?? []).concat(childNodes.flatMap(f => f.fileList))}
      if(saveDraft)
        await axios.post(`content/${this.editorStructure.id}/draft`, {structure: JSON.stringify(tmp), fileList: ret.fileList})
      return ret
    },    
    collapse(){
      this.collapsed = true
    },
    expand(){
      this.collapsed = false
    },
    collapseAll(){
      this.$refs.list?.forEach(child => {
        child.collapse()
      })
    },
    expandAll(){
      this.$refs.list?.forEach(child => {
        child.expand()
      })
    },
    async removeItem(index){
      if(this.structure.list[index].id)
        await axios({ url: `content/${this.structure.list[index].id}`, method: "DELETE" })
      this.structure.list.splice(index, 1)
      //this.editorStructure.children.splice(index, 1)
    },
    moveDown(index){      
      if(index < (this.structure.list.length + 1)){
        this.structure.list.move(index, index + 1)
        for (let i = 0; i < this.structure.list.length; i++) {
          this.structure.list[i].order = i          
        }
      }
    },
    moveUp(index){      
      if(index > 0){
        this.structure.list.move(index, index - 1)
        for (let i = 0; i < this.structure.list.length; i++) {
          this.structure.list[i].order = i          
        }
      }
    },
    async save(){
      await this.$refs.observer.validate()
      if((!await validateS3Files(this) || this.$refs.observer?.flags.invalid) && !this.showSaveConfirm){
        this.showSaveConfirm = true
        return
      }

      try{
        this.loading = true
        // eslint-disable-next-line
        const {list, ...temp} = this.structure
        temp.id = this.editorStructure.id
        let resp = await axios({ url: `content/folder${this.structure.id ? ('/'+this.structure.id) : ''}`, data: ({
          structure: JSON.stringify(temp), 
          fileList: this.editorStructure.fileList,
          parentId: this.editorStructure.parentId,
          order: this.editorStructure.order}), method: "POST" })
        this.editorStructure.id = this.structure.id = resp.data.id // update for newly created
        this.$refs.observer.reset()
        await this.$refs.observer.validate()
      }
      catch(e){
        this.$refs.snackbar.show('Something went wrong :(')
        console.log(e)
      }
      finally{
        this.loading = false
        this.showSaveConfirm = false
      }
    }
  },
  watch: {   
    'editorStructure.order' : async function(n, o) {
      console.log(`Order changed for item: ${this.structure.id} from ${o} to ${n}`)
      try{
        this.loading = true
        if(this.structure.id)
          await axios.post(`content/${this.structure.id}/reorder/${n}`)
      }      
      catch(e){
        this.$refs.snackbar.show('Something went wrong :(')
        console.log(e)
      }
      finally{
        this.loading = false
      }      
    }
  },
  async mounted(){
    this.editorStructure?.children?.forEach(item => {
      item.editorId = item.editorId ?? generateEditorId()
    })
    if(this.editorStructure?.structure)
      Object.assign(this.structure, this.editorStructure?.structure)
    if(this.editorStructure?.children)
      this.structure.list = _.sortBy(this.editorStructure.children.map(c => {c.structure = JSON.parse(c.structure); return c;}),'order')
    this.structure.id = this.editorStructure.id

    // if(!this.editorStructure.id)  // newly created
    //   await this.save()

    // initial validation
    setTimeout(() => this.$refs.observer.validate(), 300)
  }
}
</script>



<style lang="scss">

.contenteditor{
  .item.conditional{
    
  }
}

</style>
