<template>
  <div class="item root" :class="{loading: loading}">  
    <!-- <button @click="debug" >debug</button> -->
    <ValidationObserver v-slot="{ dirty, invalid }" ref="observer">  
    <div class="header" style="cursor: unset;">
      <b>Root content item</b> [ id: {{structure.id ? editorStructure.id : '--'}} ]
      <span v-if="invalid" class="invalid">⚠</span>
      <span v-if="!structure.id || dirty" class="unsaved">Unsaved Changes</span>      
      <button class="insead white autowidth icon" v-show="!showSaveConfirm" :class="{dirty: dirty}" :disabled="loading || parentLoading" @click.stop="save">
        <img v-if="!(loading || parentLoading)" src="/save.png">
        <img v-else src="/spinner.svg">
      </button>
      <button class="insead white autowidth icon" :disabled="loading || parentLoading" v-show="showSaveConfirm" @click.stop="showSaveConfirm = false">Cancel</button>
      <button class="insead white autowidth icon" :disabled="loading || parentLoading" v-show="showSaveConfirm" @click.stop="save">Yes, save <i>invalid</i> item</button>
    </div>    
    <table>      
      <tr class="inputrow"><td>bg:</td><td>
        <ValidationProvider name="bg" rules="imageUrl" v-slot="{ classes, errors }">
          <input type="text" required v-model.trim="structure.bg" :disabled="loading || parentLoading || showSaveConfirm" :class="classes">
          <span class="errormessage">{{ errors[0] }}</span>
        </ValidationProvider>
      </td></tr>
      <tr class="inputrow"><td>title:</td><td> 
        <ValidationProvider name="title" v-slot="{ classes, errors }">
          <input type="text" required v-model.trim="structure.title" :disabled="loading || parentLoading || showSaveConfirm" :class="classes">
          <span class="errormessage">{{ errors[0] }}</span>
        </ValidationProvider>
      </td></tr>
      <tr class="inputrow"><td>txt1:</td><td> 
        <ValidationProvider name="txt1" v-slot="{ classes, errors }">
          <input type="text" required v-model.trim="structure.txt1" :disabled="loading || parentLoading || showSaveConfirm" :class="classes">
          <span class="errormessage">{{ errors[0] }}</span>
        </ValidationProvider>
      </td></tr>
      <tr class="inputrow"><td>txt2:</td><td>
        <ValidationProvider name="txt2" v-slot="{ classes, errors }">
          <input type="text" v-model.trim="structure.txt2" :disabled="loading || parentLoading || showSaveConfirm" :class="classes">
          <span class="errormessage">{{ errors[0] }}</span>
        </ValidationProvider>
      </td></tr>
      <tr class="inputrow"><td>icon:</td><td> 
        <ValidationProvider name="icon" rules="imageUrl" v-slot="{ classes, errors }">
          <input type="text" v-model.trim="structure.icon" :disabled="loading || parentLoading || showSaveConfirm" :class="classes">
          <span class="errormessage">{{ errors[0] }}</span>
        </ValidationProvider>
      </td></tr>
      <tr><td><strike>menuFile:</strike></td><td> <input type="text" v-model.trim="structure.menuFile" :disabled="loading || parentLoading"></td></tr>
      <tr class="inputrow"><td><strike>webContentRootPath:</strike></td><td> 
        <ValidationProvider name="webContentRootPath" rules="webContentRootPath" v-slot="{ classes, errors }">
          <input type="text" v-model.trim="structure.webContentRootPath" :disabled="loading || parentLoading || showSaveConfirm" :class="classes" placeholder="the path to the (root) folder on storage where files are uploaded for this experience">
          <span class="errormessage">{{ errors[0] }}</span>
        </ValidationProvider>
      </td></tr>
      
      <tr>
        <td>list:</td>
        <td class="expandbuttons">
          <button class="insead white autowidth icon" @click="collapseAll" title="Collapse all items"><img src="/collapse.png"></button>
          <button class="insead white autowidth icon" @click="expandAll" title="Expand all items"><img src="/expand.png"></button>
        </td>
      </tr>
    </table>
    </ValidationObserver>
    <div class="children">
      <div class="label" v-if="structure.list && structure.list.length">
        <span v-if="$refs.observer.flags.dirty || $refs.observer.flags.invalid" :class="{dirty: $refs.observer.flags.dirty, invalid: $refs.observer.flags.invalid}">⚠</span>
        <div>Root: <span>{{structure.title}}</span></div>
      </div>
      <div class="items">
        <div class="insertactions" v-if="structure.id">
          <img width="16" height="16" src="/plus.png" />
          <div style="width: 225px;">
            <button class="insead autowidth white" @click="addItem(0, 'seg')" >Segment</button>
            <button class="insead autowidth white" @click="addItem(0, 'conditional')" >Conditional</button>
            <button class="insead autowidth white" @click="addItem(0, 'list')" >List</button>
          </div>
        </div>
        <div style="color: #a0a0a0;" v-else>Note: newly added root must be saved before child items can be added.</div>
        <template v-for="(item, index) in structure.list" >
          <component v-if="itemType(item.structure)" :key="item.editorId"
            ref="list"
            :is="itemType(item.structure)"
            :editorStructure="item"
            :parentLoading="loading || parentLoading"
            @remove="removeItem(index)"
            @up="moveUp(index)"
            @down="moveDown(index)"
          /> 
          <div class="insertactions" v-if="itemType(item.structure)" :key="generateEditorId() +'actions_' + index">
            <img width="16" height="16" src="/plus.png" />
            <div style="width: 225px;">
              <button class="insead autowidth white" @click="addItem(index+1, 'seg')" >Segment</button>
              <button class="insead autowidth white" @click="addItem(index+1, 'conditional')" >Conditional</button>
              <button class="insead autowidth white" @click="addItem(index+1, 'list')" >List</button>
            </div>
          </div>  
        </template>
      </div>
    </div>  

    <Snackbar ref="snackbar" />  
  </div>
</template>


<script>
// eslint-disable-next-line
import {getContentComponentType, getEmptyContent, generateEditorId, validateS3Files} from '@/utils.js'
import ListItem from '@/components/content/List.vue'
import ConditionalItem from '@/components/content/Conditional.vue'
import SegmentItem from '@/components/content/Segment.vue'
import axios from 'axios'
import _ from 'lodash'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import '@/veevalidate.js'
import Snackbar from '@/components/Snackbar.vue'

export default {
  name: 'RootItem',
  components: {
    ListItem,
    ConditionalItem,
    SegmentItem,
    ValidationProvider,
    ValidationObserver,
    Snackbar
  },
  props: {
    editorStructure: Object,
    parentLoading: Boolean
  },
  data: function() {
    return {
      showSaveConfirm: false,
      fileFields: ['bg', 'icon'],
      structure: {
        type: "list",
        id: undefined,
        bg: undefined,
        title: undefined,
        txt1: undefined,
        txt2: undefined,
        icon: undefined,
        menuFile: undefined,
        webContentRootPath: undefined,
        list: []        
      },
      loading: false      
    }
  },
  computed: {
    // structure(){
    //   return{
    //     ...this.defaultStructure,
    //     ...this.editorStructure
    //   }
    // }
    // dirty(){
    //   return this.$refs.observer?.flags.dirty
    // },
    // invalid(){
    //   return this.$refs.observer?.flags.invalid
    // }
  },
  methods: {    
    // debug(){
    //   console.log(this.$refs.observer.flags)
    // },
    generateEditorId(){
      return generateEditorId()
    },// eslint-disable-next-line
    addItem(index, type){ 
      let newItem = {
        editorId: generateEditorId(),
        parentId: Number(this.editorStructure.id),
        order: index,
        structure: getEmptyContent(type)
      }
      this.structure.list.splice(index, 0, newItem)       
      for (let i = 0; i < this.structure.list.length; i++) {
        this.structure.list[i].order = i          
      }     
    }, 
    itemType(item){
      return getContentComponentType(item)
    },
    async generate(saveDraft){
      // eslint-disable-next-line
      let {list, ...tmp} = this.structure  
      const childNodes = this.$refs.list ? await Promise.all( this.$refs.list.map(async l => await l.generate(saveDraft))) : []  
      tmp.list = childNodes.map(n => n.json)
      const ret = {json: tmp, fileList: (this.editorStructure.fileList ?? []).concat(childNodes.flatMap(f => f.fileList))}
      if(saveDraft)
        await axios.post(`content/${this.editorStructure.id}/draft`, {structure: JSON.stringify(tmp), fileList: ret.fileList})
      return ret
    },
    collapseAll(){
      this.$refs.list?.forEach(child => {
        child.collapse()
      })
    },
    expandAll(){
      this.$refs.list?.forEach(child => {
        child.expand()
      })
    },
    async removeItem(index){
      // let index = this.structure.list.findIndex(i => i.editorId == editorId)
      // let editorId = this.structure.list[index].editorId
      // console.log("removing item at index " + index + " with editorId " + editorId)
      await axios({ url: `content/${this.structure.list[index].id}`, method: "DELETE" })
      this.structure.list.splice(index, 1)
      //this.editorStructure.children.splice(index, 1)
    },
    moveDown(index){      
      if(index < (this.structure.list.length + 1)){
        this.structure.list.move(index, index + 1)
        for (let i = 0; i < this.structure.list.length; i++) {
          this.structure.list[i].order = i          
        }
      }
    },
    moveUp(index){      
      if(index > 0){
        this.structure.list.move(index, index - 1)
        for (let i = 0; i < this.structure.list.length; i++) {
          this.structure.list[i].order = i          
        }
      }
    },
    async save(){
      await this.$refs.observer.validate()
      if((!await validateS3Files(this) || this.$refs.observer?.flags.invalid) && !this.showSaveConfirm){
        this.showSaveConfirm = true
        return
      }

      try{
        this.loading = true
        // eslint-disable-next-line
        const {editorId, list, ...temp} = this.structure
        temp.id = this.editorStructure.id
        let resp = await axios({ url: `content/master/${this.editorStructure.id}`, data: ({
          structure: JSON.stringify(temp),
          fileList: this.editorStructure.fileList
          }), method: "POST" })
        // this.lastStructure = {...this.structure}
        // delete this.lastStructure.list
        this.editorStructure.id = this.structure.id = resp.data.id // update for newly created
        this.$refs.observer.reset()
        await this.$refs.observer.validate()
      }
      catch(e){
        this.$refs.snackbar.show('Something went wrong :(')
        console.log(e)
      }
      finally{
        this.loading = false
        this.showSaveConfirm = false
      }
    }
  },
  watch: { 
    editorStructure(n){
      this.editorStructure?.children?.forEach(item => {
        item.editorId = item.editorId ?? generateEditorId()
      })
      Object.assign(this.structure, JSON.parse(n.structure))
      this.structure.list = _.sortBy(n.children.map(c => {c.structure = JSON.parse(c.structure); return c;}), 'order')
      // this.lastStructure = {...this.structure}
      // delete this.lastStructure.list
    },
    'structure.webContentRootPath': function(newValue){      
      this.$store.state.editorWebContentRootPath = newValue
    }
    // editorStructure: {
    //   handler(n, o){
    //     console.log("Root item watcher")
    //     console.log(n)
    //     console.log(o)
    //     this.$set(this.structure, 'list', n.list)
    //     Object.assign(this.structure, n)
    //     // Object.assign(this.structure, newItem)        
    //     this.$forceUpdate();
    //   },
    //   deep: true
    // },
    // 'editorStructure.list' : function(n, o) {
    //   console.log("Root item list watcher")
    //   console.log(n)
    //   console.log(o)
    // },
    // '$props':{
    //   handler: function (n, o) { 
    //     console.log('Root item props watch')
    //     console.log(n)
    //     console.log(o)
    //   },
    //   deep: true
    // }
  },
  mounted(){
    this.editorStructure?.children?.forEach(item => {
      item.editorId = item.editorId ?? generateEditorId()
    })
    if(this.editorStructure?.structure)
      Object.assign(this.structure, JSON.parse(this.editorStructure?.structure))
    if(this.editorStructure?.children)
      this.structure.list = _.sortBy(this.editorStructure.children.map(c => {c.structure = JSON.parse(c.structure); return c;}),'order')
    
    // initial validation
    setTimeout(() => this.$refs.observer.validate(), 300)
  }
}
</script>



<style lang="scss">

.contenteditor{
  .item.root{
    margin-right: 10px;

    // table{ 
    //   tr {
    //     td:first-child{
    //       width: 180px;
    //     }
    //   }
    // }
  }
}

</style>
