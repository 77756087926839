<template>
  <div class="item video" :class="{collapsed: collapsed, deletewarn: showDeleteConfirm, loading: loading}">
    <ValidationObserver v-slot="{ dirty, invalid }" ref="observer">
    <div class="header" @click="collapsed = !collapsed">
      <b>Video</b> [ id: {{structure.id ? structure.id : '--'}} ] <span class="toggle">&lt;</span>
      <span v-if="invalid" class="invalid">⚠</span>
      <span v-if="!editorStructure.id || dirty" class="unsaved">Unsaved Changes</span> 
      <!-- <span class="label">{{structure.id}}</span> -->
      <button class="insead white autowidth icon loader" v-show="!showSaveConfirm" :class="{dirty: dirty}" :disabled="loading || parentLoading" @click.stop="save">
        <img v-if="!loading" src="/save.png">
        <img v-else src="/spinner.svg">
      </button>
      <button class="insead white autowidth icon" v-show="showSaveConfirm" :disabled="loading || parentLoading" @click.stop="showSaveConfirm = false">Cancel</button>
      <button class="insead white autowidth icon" v-show="showSaveConfirm" :disabled="loading || parentLoading" @click.stop="save">Yes, save <i>invalid</i> item</button>
      <button class="insead white autowidth icon" v-show="!showDeleteConfirm" :disabled="loading || parentLoading" @click.stop="showDeleteConfirm = true" title="Remove item"><img src="/delete.png"></button>
      <button class="insead white autowidth icon" v-show="showDeleteConfirm" :disabled="loading || parentLoading" @click.stop="showDeleteConfirm = false">Cancel</button>
      <button class="insead white autowidth icon" v-show="showDeleteConfirm" :disabled="loading || parentLoading" @click.stop="$emit('remove'); showDeleteConfirm = false;">Yes, confirm</button>
      <button class="insead white autowidth icon" @click.stop="$emit('up');" :disabled="loading || parentLoading" title="Move up"><img src="/triangle.png"></button>
      <button class="insead white autowidth icon" @click.stop="$emit('down');" :disabled="loading || parentLoading" title="Move down"><img style="transform: rotate(180deg);" src="/triangle.png"></button>
    </div>
    <table v-show="!collapsed">
      <!-- <tr><td>id:</td><td> <input type="number" v-model.number="structure.id"></td></tr> -->
      <tr class="inputrow">
        <td>fname:</td>
        <td> 
          <ValidationProvider :name="editorStructure.editorId + 'fname'" rules="videoUrl" v-slot="{ classes, errors }">
            <input type="text" required v-model.trim="structure.fname" :disabled="loading || parentLoading || showSaveConfirm" :class="classes">
            <span class="errormessage">{{ errors[0] }}</span>
          </ValidationProvider>
        </td>
        <!-- <td class="preview" rowspan="3">
          <img :src="`${blobRoot}${$store.state.editorWebContentRootPath}${structure.fname}`">
        </td> -->
      </tr>
      <tr class="inputrow">
        <td>duration:</td>
        <td> 
          <ValidationProvider :name="editorStructure.editorId + 'duration'" v-slot="{ classes, errors }">
            <input type="number" v-model="structure.duration" :disabled="loading || parentLoading || showSaveConfirm" :class="classes">
            <span class="errormessage">{{ errors[0] }}</span>
          </ValidationProvider>
        </td>
      </tr>
      <tr class="inputrow">
        <td>path:</td>
        <td>
          <ValidationProvider :name="editorStructure.editorId + 'path'" v-slot="{ classes, errors }">
            <input style="width: 50%;" type="text" v-model="path_internal" :disabled="loading || parentLoading || showSaveConfirm" :class="classes">
            <span class="errormessage">{{ errors[0] }}</span>
          </ValidationProvider>
          <span class="path" :class="{invalid: !p}" v-for="(p,index) in structure.path" :key="p + index">{{p ? p : 'invalid'}}</span>
        </td>
      </tr>
      <tr class="inputrow">
        <td>heatmap:</td>
        <td> 
          <ValidationProvider :name="editorStructure.editorId + 'heatmap'" v-slot="{ classes, errors }">
            <input type="checkbox" v-model="structure.heatmap" :disabled="loading || parentLoading || showSaveConfirm" :class="classes">
            <span class="errormessage">{{ errors[0] }}</span>
          </ValidationProvider>
        </td>
      </tr>  
      <tr class="inputrow">
        <td>heatmapWebVid:</td>
        <td> 
          <ValidationProvider :name="editorStructure.editorId + 'heatmapWebVid'" :rules="`videoUrl|proxyFile|required_if:${editorStructure.editorId}heatmap`" v-slot="{ classes, errors }">
            <input type="text" :disabled="!structure.heatmap || loading || parentLoading || showSaveConfirm" v-model.trim="structure.heatmapWebVid" :class="classes">
            <span class="errormessage">{{ errors[0] }}</span>
          </ValidationProvider>
        </td>
      </tr>
    </table>    
    </ValidationObserver>   
    <Snackbar ref="snackbar" />  
  </div>
</template>


<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import '@/veevalidate.js'
import _ from 'lodash'
import axios from 'axios'
import Snackbar from '@/components/Snackbar.vue'
import {validateS3Files} from '@/utils.js'

export default {
  name: 'VideoItem',
  components:{
    ValidationProvider,
    ValidationObserver,
    Snackbar
  },
  props: {
    editorStructure: Object,
    parentLoading: Boolean
  },
  data: function() {
    return {
      showDeleteConfirm: false,
      showSaveConfirm: false,
      collapsed: false,
      loading: false, 
      fileFields: ['fname','heatmapWebVid'],
      structure: {
        $type: 'VideoData, Assembly-CSharp',
        type: "vid",
        id: undefined,
        fname: undefined,
        duration: undefined,
        path: undefined,
        heatmap: undefined,
        heatmapWebVid: undefined
      }
    }
  },
  computed: {
    path_internal: {
      get: function () {
        let tmp = this.structure.path?.join(',')
        return tmp?.length ? tmp : undefined
      },
      set: function (newValue) {
        let tmp = newValue?.trim().replace(/[^0-9,]+/g, '')
        
        this.structure.path = tmp.length ? tmp.split(',') : undefined
      }
    }
  },
  methods: {
    generate(){
      let tmp = _.cloneDeep(this.structure)  
      tmp.path = tmp.path?.filter(x => x).map(x => parseInt(x))
      return {json: tmp, fileList: Array.from(this.editorStructure.fileList ?? [])}
    },
    collapse(){
      this.collapsed = true
    },
    expand(){
      this.collapsed = false
    },
    async save(){
      await this.$refs.observer.validate()
      if((!await validateS3Files(this) || this.$refs.observer?.flags.invalid) && !this.showSaveConfirm){
        this.showSaveConfirm = true
        return
      }

      try{
        this.loading = true
        // eslint-disable-next-line
        const {list, ...temp} = this.structure
        temp.id = this.editorStructure.id
        let resp = await axios({ url: `content/item${this.structure.id ? ('/'+this.structure.id) : ''}`, data: ({
          structure: JSON.stringify(temp), 
          fileList: this.editorStructure.fileList,
          parentId: this.editorStructure.parentId,
          order: this.editorStructure.order}), method: "POST" })
        this.editorStructure.id = this.structure.id = resp.data.id // update for newly created
        this.$refs.observer.reset()
        await this.$refs.observer.validate()
      }
      catch(e){
        this.$refs.snackbar.show('Something went wrong :(')
        console.log(e)
      }
      finally{
        this.loading = false
        this.showSaveConfirm = false
      }
    }
  },
  watch: {    
    'editorStructure.order' : async function(n, o) {
      console.log(`Order changed for item: ${this.structure.id} from ${o} to ${n}`)
      try{
        this.loading = true
        if(this.structure.id)
          await axios.post(`content/${this.structure.id}/reorder/${n}`)
      }      
      catch(e){
        this.$refs.snackbar.show('Something went wrong :(')
        console.log(e)
      }
      finally{
        this.loading = false
      }      
    }  
  },
  async mounted(){
    if(this.editorStructure?.structure)
      Object.assign(this.structure, this.editorStructure?.structure)
    
    this.structure.id = this.editorStructure.id

    // if(!this.editorStructure.id)  // newly created
    //   await this.save()
    
    // initial validation
    setTimeout(() => this.$refs.observer.validate(), 300)
  }
}
</script>



<style lang="scss">

.contenteditor{
  .item.video {

  }
}

</style>
